$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;

$blue: #066edd;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #e83e8c;
$red: #ea2529;
$orange: #f89521;
$yellow: #ffc107;
$green: #28a745;
$teal: #20c997;
$cyan: #17a2b8;

$dark: #333333;
$light: $gray-100;



$component-active-bg: $blue;
$primary: $blue;
$accent: $orange;
$secondary: $gray-600;
$success: $green;
$info: $cyan;
$warning: $yellow;
$danger: $red;

$text-color-light: $gray-100;
$text-color-dark: $dark;
